// Here you can add other styles
.react-toast-notifications__container {
  margin-top: 50px;
}

.btn.active {
  box-shadow: 0 0 0 0.1rem rgba(81, 65, 224, 0.5);
}

.table-part-row {
  line-height: 0;
  transition: 1s;
  opacity: 0;
  padding: 0 !important;
}
.table-part-row.show {
  line-height: 1em;
  transition: 1s;
  opacity: 1;
  padding: auto !important;
}

.c-sidebar-brand {
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  padding-left: 12px;
  justify-content: normal;
}

.stretch-input {
  flex: 1 1 auto;
}

.c-avatar {
  font-size: 35px;
}

.user-avatar {
  border-radius: 50%;
  width: 100px;
}

/*************** LOADER **/

/** CSS for animated bouncing loader. */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.s-load {
  justify-content: start;
  margin-top: 15px;
}

.loader.l-load {
  justify-content: start;
  margin-top: 15px;
}

/** Loader circles **/
.loader > span {
  background: #fad000;
  border-radius: 50%;
  margin: 5rem 0.5rem;
  animation: bouncingLoader 0.6s infinite alternate;
}

.loader.s-load > span {
  margin: 0.1rem 0.5rem;
  animation: sm-bouncingLoader 0.6s infinite alternate;
}

.loader.l-load > span {
  margin: 0.1rem 0.5rem;
}

.loader > span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 1rem;
    height: 1rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}

@keyframes sm-bouncingLoader {
  from {
    width: 0.1rem;
    height: 0.1rem;
    opacity: 1;
    transform: translate3d(0);
  }
  to {
    width: 0.6rem;
    height: 0.6rem;
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}
/*************** END LOADER **/
.card-title {
  font-weight: bold;
  font-size: 16px;
}

.card-header {
  padding: 1rem 1.5rem 0.5rem 1.5rem;
}

.context-menu {
  display: inline;
}

.context-menu button {
  padding: 1px 1px 1px 3px;
}

.form-control-sm {
  font-size: 0.5em;
}

.check-width {
  width: 20px;
}

.drag-area {
  font-size: 1.25rem;
  background-color: #c8dadf;
  position: relative;
  padding: 30px 20px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
}

.drag-area-icon {
  width: 100%;
  height: 80px;
  fill: #92b0b3;
  display: block;
  margin-bottom: 40px;
}

.toolbar-sm {
  padding: 0.2rem 0.25rem;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.dnd-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dnd-container > .column {
  margin: 10px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
}

.dnd-container > .column > .header {
  font-weight: bold;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.dnd-container .movable-item {
  background-color: #fff3f3;
  margin: 10px auto;
  padding: 10px;
  // width: 100%;
  // max-height: 40px;
  // justify-content: center;
  // align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}
